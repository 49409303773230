import Vue from 'vue';
import VueHtml2Canvas from 'vue-html2canvas';

import App from './App.vue';
import store from './store';

import database from './mixins/database';
import utils from './mixins/utils';
import instance from './plugins/axios';

import '@fortawesome/fontawesome-free/css/all.css';

import '@/assets/bootstrap.min.css';
import '@/assets/style.css';

Vue.prototype.$http = instance;

Vue.config.productionTip = false;

Vue.use(VueHtml2Canvas);

Vue.mixin(database);
Vue.mixin(utils);

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
