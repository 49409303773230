<template>
  <div class="left-pane">
    <modal
      name="download"
      title="You've got Mail!"
      backhead="email"
    >
      <div class="font-size-20">We sent the image to:</div>
      <div class="font-size-20"><b>{{ email }}</b></div>
      <div class="font-weight-thin font-size-12 mt-3">
        (You can close this window by clicking behind)
      </div>
    </modal>
    <div v-if="isMerch">
      <h1 class="product-name">{{ productName }}</h1>
      <span class="price">{{ price }}</span>
    </div>
    <div :class="{ 'merch-display': isMerch }">
      <h2 v-if="isMerch">Customise your Lofi Avatar</h2>
      <span class="font-weight-bold">Reset and randomize your character</span>
      <div class="left-pane--child left-pane--child--reset">
        <label
          class="asset--selector-asset m-0 p-0"
          v-on:click="randomizeCharacter"
        >
          <icon path="icons/retry.png"></icon>
        </label>
      </div>
      <span class="font-weight-bold">Change anatomy</span>
      <div class="left-pane--child">
        <body-type></body-type>
      </div>
      <span class="font-weight-bold">Change body type</span>
      <div class="left-pane--child">
        <anatomy></anatomy>
      </div>
      <span v-if="!isMerch" class="font-weight-light">
        Note: when changing the anatomy or body type the clothes can change
        depending on their availability within it.
      </span>
      <span class="mt-2 d-block font-weight-bold">Change skin color</span>
      <div class="left-pane--child left-pane--slider">
        <skin-color></skin-color>
      </div>
      <span v-if="!isMerch" class="font-weight-bold">Download your character!</span>
      <div v-if="!isMerch" class="left-pane--child left-pane--child--download position-relative">
        <div class="button--download-container">
          <div :class="{ error: emailError }" class="button--download">
            <input placeholder="YourEmail@gmail.com" type="text" v-model="email">
            <button v-on:click="download">
              <div>
                Download <i class="ml-1 fa fa-download"></i>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div v-if="!isMerch" class="mb-2" :class="{ 'mt-4': emailError, 'pt-2': emailError }">
        <div class="download-condition">
          <input id="check-condition" type="checkbox" class="mr-2" v-model="terms">
          <label for="check-condition">
            By downloading, I agree to the
            <a href="https://lofirecords.com/pages/terms-and-conditions" target="_blank">terms and conditions</a>
          </label>
        </div>
        <div class="download-condition mt-3">
          <input id="check-newsletter" type="checkbox" class="mr-2" v-model="newsletter">
          <label for="check-newsletter">
            I accept to signing up to this newsletter and I understand that
            I can opt out of the emailing list at any time.
          </label>
        </div>
      </div>
      <span v-if="!isMerch" class="annotation">
        * All artworks downloaded through this generator
        are under the Lofi Girl trademarks and property rights, they
        cannot be modified (or animated) and must solely be used for personal
        use, any other use is strictly prohibited.
      </span>
    </div>
  </div>
</template>

<script>
import Anatomy from './Anatomy.vue';
import BodyType from './BodyType.vue';
import SkinColor from './SkinColor.vue';
import Icon from './Icon.vue';
import Modal from './Modal.vue';

export default {
  name: 'LeftPane',

  components: {
    BodyType,
    Anatomy,
    SkinColor,
    Icon,
    Modal,
  },

  props: {
    isMerch: Boolean,
  },

  data: () => ({
    emailError: false,
    email: null,
    terms: false,
    newsletter: false,
    productName: 'Tshirt',
    price: '30.00',
  }),

  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queries = Object.fromEntries(urlSearchParams.entries());

    if (queries.productName) {
      this.productName = queries.productName;
    }
    if (queries.price) {
      this.price = queries.price;
    }

    this.$root.$on('update:price', (price) => {
      this.price = price;
    });
  },

  methods: {
    randomizeCharacter() {
      this.$root.$emit('character:randomize');
    },

    validateEmail(email) {
      return (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
        && this.terms
      );
    },

    download() {
      if (!this.validateEmail(this.email)) return this.emailError = true;
      this.emailError = false;
      this.$http.post('users', { email: this.email, character: JSON.stringify(this.character) });
      return this.$root.$emit('modal:download:open', true);
    },
  },

  watch: {
    email() {
      this.emailError = false;
    },
  },
};
</script>

<style>

.font-size-20 { font-size: 2rem; }
.font-size-12 { font-size: .7rem; }
.font-weight-thin { font-weight: 300; }

@media screen and (max-width: 870px) {
  .font-size-20 { font-size: 1.3rem; }
  .font-size-12 { font-size: .6rem; }
}

.product-name {
  text-transform: none;
  font-size: 60px;
  margin-bottom: 5px;
  line-height: 60px;
  font-weight: 700;
  font-family: "Kanit", sans-serif;
}

.price {
  color: #2f6950;
  font-size: 35px;
  font-weight: 600;
  font-family: "Kanit", sans-serif;
}

.merch-display {
  border-radius: 25px;
  padding: 2em;
  background-color: #eeeeee;
}

.merch-display h2 {
  font-size: 1.8em;
  color: #386951;
}

.annotation {
  line-height: 11px;
  font-size: 11px;
  font-weight: 300;
  color: rgb(151, 151, 151);
  display: block;
}

.download-condition {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.download-condition label {
  margin-bottom: 0;
}

.asset--selector-asset {
  height: 79px;
}

.left-pane {
  text-align: left;
  box-sizing: border-box;
}

.left-pane--child {
  display: table;
  border-radius: 20px;
  margin: 10px 0;
  background-color: #eee;
  padding: 15px 10px;
}

.left-pane--child--reset {
  padding: 0;
}
.left-pane--child--reset > .asset--selector-asset {
  border: 0;
}

.left-pane--child.left-pane--slider {
  width: 300px;
}

.left-pane--child--download {
  padding: 0;
  min-width: 350px;
}

.button--download-container {
  font-family: Kanit, sans-serif;
  font-size: 15px;
  z-index: 98;
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.button--download {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #eee;
  border: 2px solid #eee;
  border-radius: 50px;
}
.button--download input {
  padding: 0 20px;
  width: 100%;
  border: 0;
  background-color: transparent;
}
.button--download input:focus { outline: 0; }
.button--download button {
  cursor: pointer;
  height: 40px;
  margin: 0 2px;
  padding: 0 10px;
  border: 0;
  width: 167px;
  color: #fff;
  border-radius: 22px;
  background-color: #144D34;
}

.button--download.error::after {
  content: 'You must accept terms and enter a valid email';
  color: rgb(211, 44, 44);
  height: 20px;
  line-height: 17px;
  font-size: 12px;
  width: 90%;
  text-align: center;
  background-color: #eee;
  border: 2px solid #eee;
  border-radius: 50px;
  position: absolute;
  bottom: -30px;
}

@media screen and (max-width: 560px) {
  .left-pane { text-align: center; }

  .left-pane--child, .left-pane--child.left-pane--slider {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .left-pane--child.left-pane--child--reset {
    background: none;
  }
}

</style>
