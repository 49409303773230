<template>
  <div class="merch">
    <div class="text">
      <div class="text-container">
        <h1>Your avatar on real Lofi Merch!</h1>
        <p>
          Lofi is printing your own designs on t-shirts and sweatshirts! Try it out now!
        </p>
      </div>
    </div>
    <div class="merch-items" :style="{ 'color': (isNewFront()) ? '#1f1f36' : 'inherit' }">
      <a href="https://lofigirlshop.com/products/personalized-merch?product=tshirt" target="blank" class="merch-item">
        <div class="merch-item-image">
          <img class="merch-item-image-item" src="@/assets/img/tshirt.png" />
          <character-image
            :center="true"
            size="contain"
            left="85px"
            :top="80"
            :specificSize="{ width: '70px' }"
          ></character-image>
        </div>
        <div class="merch-item-title">
          <span>On t-shirts!</span>
        </div>
      </a>

      <a href="https://lofigirlshop.com/products/personalized-merch?product=sweatshirt" target="blank" class="merch-item">
        <div class="merch-item-image">
          <img class="merch-item-image-item" src="@/assets/img/sweatshirt.png" />
          <character-image
            :center="true"
            size="contain"
            left="87px"
            :top="85"
            :specificSize="{ width: '70px' }"
          ></character-image>
        </div>
        <div class="merch-item-title">
          <span>On Hoodie!</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import CharacterImage from './CharacterImage.vue';

export default {
  components: { CharacterImage },
  name: 'Merch',
};
</script>

<style scoped>

h1 {
  font-weight: 900;
}

.merch {
  margin-top: 20px;
  width: 100%;
  display: flex;
}

.text {
  width: 35%;
  margin-right: 20px;
}

.text-container {
  position: relative;
}

.text-container::after {
  content: '';
  position: absolute;
  right: 80px;
  bottom: -80px;
  display: block;
  height: 50px;
  width: 80px;
  background: url('~@/assets/img/arrow.png') no-repeat;
  background-size: cover;
}

.merch-item-image {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #a7bdbf;
  border-radius: 20px;
  width: 100%;
  height: 85%;
  overflow: hidden;
}
.merch-item-image img {
  width: 100%;
}

.merch-item-title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 15%;
}

.merch-item-title span {
  font-size: 1.2rem;
  font-weight: 900;
  margin-left: 10px;
}

.merch-items {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
}

.merch-item {
  height: 330px;
  width: 270px;
  background-color: #eeeeee;
  border-radius: 30px;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 20px;
  color: inherit;
}

.merch-item-image-item {
  position: absolute;
  padding: 15px;
}

@media screen and (max-width: 870px) {
  .merch {
    flex-direction: column;
    justify-content: center;
  }

  .text-container::after {
    left: 0;
    bottom: -80px;
    width: 0px;
  }

  .text, .merch-items {
    width: 100%;
    justify-content: center;
  }

  .merch-item {
    margin: 10px 15px;
  }
}

</style>
