<template>
  <div
    id="app"
    :style="{ 'padding': (isMerch) ? 0 : undefined }"
    :class="{ 'new-front': isNewFront() }"
  >
    <modal
      name="browser"
      title="Incompatible browser"
      backhead="browser"
    >
      <div class="font-size-20">
        Your browser is not fully compatible with the generator
      </div>
      <div class="font-size-16">
        We advise you to use one of these browsers
      </div>
      <div class="font-size-20">
        <a href="https://www.google.com/chrome/">
          <img style="margin: 10px;" width="60" src="@/assets/img/chrome.png" />
        </a>
        <a href="https://www.mozilla.org/en-US/firefox/new/">
          <img style="margin: 10px;" width="60" src="@/assets/img/firefox.png" />
        </a>
        <a href="https://www.microsoft.com/en-us/edge">
          <img style="margin: 10px;" width="60" src="@/assets/img/edge.png" />
        </a>
      </div>
      <div class="font-weight-thin font-size-12 mt-3">
        (You can still try by clicking out of this window)
      </div>
    </modal>

    <header v-if="!isMerch" class="w-100 text-center mb-2">
      <h1>Lofi Generator</h1>
      Welcome to the Lofi generator, a place where you get to express yourself
    </header>
    <div class="d-flex w-100 justify-content-center sizer">
      <div v-if="!isLeftPane" class="app-container" :class="{ 'merch': isMerch }">
        <character class="my-3 display-only-mobile"></character>
        <navigator class="mr-4">
          <selector></selector>
          <character :isMerch="isMerch" class="my-3 display-only-desktop"></character>
          <color-selector class="mb-3"></color-selector>
          <asset-selector></asset-selector>
        </navigator>
        <left-pane v-if="!isNavigator" :isMerch="isMerch" class="left-pane--container"></left-pane>
      </div>
    </div>
    <div v-if="!isMerch" class="d-flex w-100 justify-content-center sizer">
      <div class="app-container">
        <merch></merch>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as ActionNames from '@/store/action-names';
import * as MutationTypes from '@/store/mutation-types';
import Skin from '@/models/skin';
import Asset from '@/models/asset';

import Character from './components/Character.vue';
import Navigator from './components/Navigator.vue';
import Selector from './components/Selector.vue';
import AssetSelector from './components/AssetSelector.vue';
import ColorSelector from './components/ColorSelector.vue';
import LeftPane from './components/LeftPane.vue';
import Merch from './components/Merch.vue';
import Modal from './components/Modal.vue';

export default {
  name: 'App',

  components: {
    Character,
    Navigator,
    Selector,
    AssetSelector,
    ColorSelector,
    LeftPane,
    Merch,
    Modal,
  },

  data: () => ({
    isMerch: false,
    isNavigator: false,
    isLeftPane: false,
  }),

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queries = Object.fromEntries(urlSearchParams.entries());

    if (queries.merch) {
      this.isMerch = true;
    }

    if (queries.navigator) {
      this.isNavigator = true;
    }

    if (queries.leftPane) {
      this.isLeftPane = true;
    }

    this.$root.$on('character:randomize', () => {
      this.randomize();
    });
  },

  async mounted() {
    if (!this.isBrowserCompatible()) {
      this.$root.$emit('modal:browser:open', true);
    }

    const localCharacter = (navigator.cookieEnabled) ? localStorage.getItem('character') || this.getCookie('character') : false;

    if (localCharacter) {
      const deminifiedLocalCharacter = this.characterDeminifier(JSON.parse(localCharacter));
      this.$store.dispatch(MutationTypes.CREATE, deminifiedLocalCharacter);
    } else {
      this.randomize();
    }

    window.addEventListener('message', (event) => {
      if (event.data.type === 'height') window.parent.postMessage(`${document.documentElement.scrollHeight}px`, '*');
      if (event.data.type === 'upload') this.$root.$emit('character:upload', event.data.id);
      if (event.data.type === 'producttype') this.$root.$emit('update:product:type', event.data.product);
      if (event.data.type === 'price') this.$root.$emit('update:price', event.data.price);
    });

    Promise.all(Array.from(document.images).filter((img) => !img.complete)
      .map((img) => new Promise((resolve) => {
        img.onload = resolve;
        img.onerror = resolve;
      })))
      .then(() => {
        window.parent.postMessage(`${document.documentElement.scrollHeight}px`, '*');
      });
  },

  methods: {
    isBrowserCompatible() {
      const { userAgent } = navigator;

      return (
        userAgent.match(/firefox|fxios/i)
        || userAgent.match(/chrome|chromium|crios/i)
        || userAgent.match(/opr\//i)
        || userAgent.match(/edg/i)
      );
    },

    getCookie(key) {
      const match = document.cookie.match(new RegExp(`${key}=(.*?);`));
      if (!match) return null;
      return match[1];
    },

    async randomize() {
      const gender = this.getRandomGender();
      const anatomy = this.getRandomAnatomy();
      const body = this.getRandomSkinByGenderAndAnatomy(gender, anatomy);
      const asset = new Asset({ ...body });
      const skin = new Skin({ id: 1, assets: [asset], color: asset.subCategory });

      await this.$store.dispatch(ActionNames.CREATE, {
        gender,
        anatomy,
        skin,
      });

      this.getRandom();
    },
  },
};
</script>

<style>
#app {
  font-family: Kanit, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  padding: 0.5em 0 5em 0;
}

.left-pane--container {
  width: 30%;
}

.app-container {
  display: flex;
  width: 70%;
}

.app-container.merch {
  width: 100%;
}

.display-only-mobile {
  display: none;
  z-index: 99;
}
.display-only-desktop { display: block; }

.new-front {
  background-color: #1f1f36;
  color: #f7f2f1;
}

@media screen and (max-width: 1170px) {
  .app-container {
    width: 95%;
  }
}

@media screen and (max-width: 1160px) {
  .app-container.merch {
    width: 95%;
    flex-direction: column;
  }
  .merch .left-pane--container {
    width: 100%;
  }

  .merch .display-only-desktop { display: none; }
  .merch .display-only-mobile { display: block; }
}

@media screen and (max-width: 870px) {
  .app-container {
    width: 95%;
    flex-direction: column;
  }
  .left-pane--container {
    width: 100%;
  }

  .display-only-desktop { display: none; }
  .display-only-mobile { display: block; }
}

</style>
