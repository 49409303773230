<template>
  <div class="w-100">
    <div class="d-flex radio-select skin-select">
      <input
        type="range"
        min="1"
        max="19"
        v-model="type"
        class="slider"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as MutationTypes from '@/store/mutation-types';
import Asset from '@/models/asset';
import Skin from '@/models/skin';

export default {
  name: 'SkinColor',

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  data: () => ({
    type: '#fadfd0',
    types: [
      { id: 1, color: '#fadfd0' },
      { id: 2, color: '#f8d2b6' },
      { id: 3, color: '#f4bb93' },
      { id: 4, color: '#d9ab7a' },
      { id: 5, color: '#d39f67' },
      { id: 6, color: '#ca8f58' },
      { id: 7, color: '#c0814b' },
      { id: 8, color: '#b17039' },
      { id: 9, color: '#a45d2c' },
      { id: 10, color: '#9d4e24' },
      { id: 11, color: '#94481e' },
      { id: 12, color: '#8a401a' },
      { id: 13, color: '#7f3a16' },
      { id: 14, color: '#6e3213' },
      { id: 15, color: '#5c280c' },
      { id: 16, color: '#512209' },
      { id: 17, color: '#461c05' },
      { id: 18, color: '#391500' },
      { id: 19, color: '#2b1000' },
    ],
  }),

  watch: {
    type(value) {
      const [body] = this.get({
        type: 'Asset',
        category: 'bodies',
        subCategory: `${value}.png`,
        gender: this.character.gender,
        anatomy: this.character.anatomy,
      });

      const mouth = this.character.assets.find((asset) => asset.subCategory === '2_mouths');

      if (value === 1) {
        if (mouth.activeVariant) mouth.activeVariant = null;
      } else {
        const [variant] = this.get({
          type: 'Variant',
          parent: mouth._id,
          color: value.toString(),
        });

        mouth.activeVariant = variant;
      }

      this.$store.dispatch(MutationTypes.UPDATE_ASSET, mouth);

      const nose = this.character.assets.find((asset) => asset.subCategory === '3_noses');

      if (value === 1) {
        if (nose.activeVariant) nose.activeVariant = null;
      } else {
        const [variant] = this.get({
          type: 'Variant',
          parent: nose._id,
          color: value.toString(),
        });

        nose.activeVariant = variant;
      }

      this.$store.dispatch(MutationTypes.UPDATE_ASSET, nose);

      const asset = new Asset(body);

      const skin = new Skin({
        ...this.character.skin,
        color: `${value.toString()}.png`,
        assets: [asset],
      });

      this.$store.dispatch(MutationTypes.UPDATE_SKIN, skin);

      this.storeNewCharacter();
    },
  },
};
</script>

<style>
.skin-select {
  height: auto !important;
  box-sizing: border-box;
}

.radio-select > span input[type="radio"] {
  display: none;
}

.radio-select > span input[type="radio"] ~ label {
  border: 1px solid #eee;
  margin: 0 5px;
  border-radius: 99px;
  transition: filter .2s ease, border .2s ease;
}
.radio-select > span input[type="radio"] ~ label.checked {
  border: 1px solid #000;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 16px;
  border-radius: 25px;
  padding: 5px;
  background: linear-gradient(90deg, #fadfd0, #2b1000);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 20px;
  border: 2px solid rgba(20, 77, 52, 0.8);
  opacity: 1;
  width: 20px;
  height: 20px;
  background: #ffffff;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  cursor: pointer; /* Cursor on hover */
}

</style>
